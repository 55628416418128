<template>
<div>
    <b-card title="" header-tag="header" v-if="flag == false">
        <template v-slot:header>
            <div class="card-title m-0">
                <h3 class="card-label m-0">New Bill</h3>
            </div>
            <div class="card-toolbar">

            </div>
        </template>

        <form class="form" novalidate="novalidate" id="product_basic" >
            <b-form-group label-for="input-1">
                <label>RECEIPT
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.receipt" type="text" name="name" class="form-control" placeholder="WO20070020"></b-form-input>
            </b-form-group>

            <b-form-group label-for="input-1">
                <label>ORDER DATE
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.order_date" type="text" name="name" class="form-control" placeholder="07/15/2020"></b-form-input>
            </b-form-group>

            <b-form-group label-for="input-1">
                <label>ORDER NUMBER
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.order_no" type="text" name="name" class="form-control" placeholder="20070020"></b-form-input>
            </b-form-group>

            <b-form-group label-for="input-1">
                <label>ADDRESS
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-textarea v-model="form.address" type="text" name="name" class="form-control" placeholder="address"></b-form-textarea>
            </b-form-group>

            
            <h6>Products</h6>
            <div v-if="form.products.length">
                <b-row class="ml-3" v-for="(product, key) in form.products" :key="key">
                    <b-col cols="2">
                        <b-form-group label-for="input-1">
                             <label>QTY
                                <sup class="text-danger font-weight-boldest">*</sup></label>
                            <b-form-input v-model="product.qty" type="text" name="name" class="form-control"
                                placeholder="Product quantity"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label-for="input-1">
                             <label>PRICE
                                <sup class="text-danger font-weight-boldest">*</sup></label>
                            <b-form-input v-model="product.price" type="text" name="name" class="form-control"
                                placeholder="Product price"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label-for="input-1">
                            <label>TYPE
                                <sup class="text-danger font-weight-boldest">*</sup></label>
                            <b-form-select name="group" v-model="product.type" :options="options">
                                
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    
                    <b-col cols="2">
                        <button class="btn btn-icon btn-outline-danger btn-sm hover-danger mr-1"
                            @click.prevent="deleteFilter(key)">
                            <i class="las la-trash-alt"></i>
                        </button>

                        <button v-if="form.products.length == key+1" class="btn btn-sm btn-outline-primary"
                            @click.prevent="newFilter">
                            <i class="las la-plus"></i>
                        </button>
                    </b-col>
                    <b-col >
                        <b-row class="ml-10" v-for="(variant, i) in product.variants" :key="i">
                            <b-col cols="3">
                                <b-form-group label-for="input-1">
                                    <label>NAME
                                        <sup class="text-danger font-weight-boldest">*</sup></label>
                                    <b-form-input v-model="variant.name" type="text" name="name" class="form-control"
                                        placeholder=""></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="3">
                                 <b-form-group label-for="input-1">
                                    <label>PRICE
                                        <sup class="text-danger font-weight-boldest">*</sup></label>
                                    <b-form-input v-model="variant.price" type="text" name="name" class="form-control"
                                        placeholder=""></b-form-input>
                                </b-form-group>
                            </b-col>
                             <b-col cols="6">
                                <button class="btn btn-icon btn-outline-danger btn-sm hover-danger mr-1"
                                    @click.prevent="deleteV(key,i)">
                                    <i class="las la-trash-alt"></i>
                                </button>

                                <button v-if="product.variants.length == i+1" class="btn btn-sm btn-outline-primary"
                                    @click.prevent="newV(key)">
                                    <i class="las la-plus"></i>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

            </div>
    
            <b-form-group label-for="input-1">
                <label>Sub Total
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.subTotal" type="text" name="name" class="form-control" placeholder="$100"></b-form-input>
            </b-form-group>

            <b-form-group label-for="input-1">
                <label>Shipping Cost
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.shippingCost" type="text" name="name" class="form-control" placeholder="$100"></b-form-input>
            </b-form-group>

            <b-form-group label-for="input-1">
                <label>Tax
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.tax" type="text" name="name" class="form-control" placeholder="$100"></b-form-input>
            </b-form-group>
            <b-form-group label-for="input-1">
                <label>Total
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.total" type="text" name="name" class="form-control" placeholder="$100"></b-form-input>
            </b-form-group>
            <b-row>
                <b-col class="text-right">
                    <button ref="kt_login_signin_submit" class="btn btn-sm btn-outline-primary font-size-h6 " @click="print()">
                       <i class="las la-save"></i> Generate
                    </button>
                </b-col>
            </b-row>
        </form>
    </b-card>
    <table width="768" style="font-family:Tahoma;color: #000000" v-else>
    <tr>
       <td>
        <table width="100%">
           <tr>
              <td width="30" style="height: 10px"></td>
              <td><a href="https://westernopticals.com" target="_blank"><img src="https://westernopticals.com/wp-content/uploads/2019/06/logo-2.png" alt="westernopticals.com" width="291" height="90"></a> </td>
              <td align="right" valign="middle" style="font-size: 19px"><br/> RECEIPT:   <strong>{{form.receipt}}</strong><br/>
               ORDER DATE    : <strong>{{form.order_date}}</strong><br/>
               ORDER NUMBER  : <strong>{{form.order_no}}</strong><br/>
               FEDERAL TAX ID: <strong>84-4450284</strong>
               </td>
              <td width="30" ></td>
            </tr>
           </table>
                </td>
    </tr>
   
    <tr> <td>
        <table  width="100%">
           <tr>
               <td width="30"></td>
               <td style="font-size: 26px;border-bottom: 1px solid #000000;padding: 5px 0 10px;"><strong>Your Order Receipt</strong></td>
               <td width="30"></td>
            </tr>
           <tr>
               <td></td>
               <td style="line-height:35px;padding: 10px 0 0">
                Thank you for purchase from <strong>westernopticals.com</strong><br/>
               
                 
                 </td>
               <td></td>
            </tr>
            <tr><td colspan="3"></td></tr>
             <tr>
                <td></td>
                <td style="font-size: 22px"><strong> FROM</strong><br/>
                 Western Opticals Inc, <br/>
                 1 Waterview Dr #102, <br/>
                 Shelton, CT 06484
                 
                 </td>
                <td></td>
            </tr>
             <tr>
                <td></td>
                <td style="height: 10px"></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td style="font-size: 22px"><strong>SHIP TO</strong><br/>
                 <span v-html="form.address"></span>
                 
                </td>
                <td></td>
            </tr>
             <tr>
                <td></td>
                <td>&nbsp;</td>
                <td></td>
            </tr>
            
             <tr>
                <td></td>
                <td>
                 <table width="100%">
                      <tr style="font-size: 18px;">
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>PURPOSE</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>P.O.NUMBER</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>COD</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>SHIPPED VIA</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px" align="right"><strong>TERMS</strong></td>
                     </tr>
                     <tr>
                       <td style="border-bottom: 1px solid #ccc;padding:  10px">Sale</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px"></td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px">USA</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px">USPS</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px" align="right"></td>
                     </tr>
                    
                    </table>
                 
                 </td>
                <td></td>
            </tr>
             <tr>
                <td></td>
                <td>&nbsp;</td>
                <td></td>
            </tr>
            
             <tr>
                <td></td>
                <td>
                 <table width="100%">
                      <tr style="font-size: 18px;">
                        <td style="border-bottom: 1px solid #000000;padding:  10px"  width="25"><strong>Qty</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>Product Detail</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>PRICE</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px" align="right"><strong>TOTAL</strong></td>
                     </tr>
                     <tr v-for="(product,i) in form.products" :key="i">
                       <td style="border-bottom: 1px solid #ccc;padding:  10px">{{product.qty}}</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px">{{product.type}}:<br/>
<span v-for="(varient,key) in product.variants" :key="key">{{varient.name}}<br/></span>
                                                                                  	</td>

                        <td style="border-bottom: 1px solid #ccc;padding:  10px"> <br/><span v-for="(varient,key) in product.variants" :key="key">{{varient.price}}<br/></span></td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px" align="right">${{product.price}}</td>
                     </tr>
                    
                     <tr>
                        <td style="border-bottom: 1px solid #000000;"></td>
                        <td style="border-bottom: 1px solid #000000;"></td>
                        <td  style="line-height: 30px;padding: 10px;font-size:20px;border-bottom: 1px solid #000000;" >
                            Sub Total<br/>
                            Shipping & Handling<br/>
                            
                            Tax
                         </td>
                        <td style="line-height: 30px;padding: 10px;font-size: 20px;border-bottom: 1px solid #000000;" align="right">
                            ${{form.subTotal}}<br/>
                            ${{form.shippingCost}}<br/>
                            
                            ${{form.tax}}
                         </td>
                     </tr>
                     <tr>
                        <td style="line-height: 30px;padding: 10px;border-bottom: 1px solid #000000;" ></td>
                        <td style="line-height: 30px;padding: 10px;border-bottom: 1px solid #000000;" ></td>
                        <td style="line-height: 30px;padding: 10px;font-size: 25px;border-bottom: 1px solid #000000;" >
                         Total
                         </td>
                        
                        <td style="line-height: 30px;padding: 10px;font-size: 25px;border-bottom: 1px solid #000000;"  align="right"><strong>${{form.total}}</strong></td>
                     </tr>
                    </table>
                 
                 </td>
                <td></td>
            </tr>
            
        </table>
        
        </td> </tr>
    <tr> <td bgcolor="#210070" style="padding:15px 10px; color: #ffffff" align="center">
        <strong>Email </strong>info@westernopticals.com
        <strong>Phone</strong> +(203) 5138302
        </td> </tr>
    <tr> <td></td> </tr>
    <tr> <td></td> </tr>
<!-- <b-row>
                <b-col class="text-right">
                    <button ref="kt_login_signin_submit" class="btn btn-sm btn-outline-primary font-size-h6 " @click="printWindow()">
                       <i class="las la-save"></i> Print
                    </button>
                </b-col>
            </b-row> -->
</table>
</div>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Swal from "sweetalert2";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
//api services
import AttributeService from "@/core/services/api/attribute";
export default {
    components: {},
    data() {
        return {
            flag:false,
            options:[{
                value:"Frames",
                text:"Frame"
            },{
                value:"Lenses",
                text:"Lens"
            }],
            form: {
                receipt: null,
                order_date: "",
                order_no: null,
                address: "",
                shippingCost:"",
                subTotal:"",
                tax:"",
                total:"",
                products:[{
                        qty:"",
						type: "",
						price:"",
                        variants:[{
                            name:"",
                            price:""
                        }]
					}]
            },
        };
    },
    mounted() {
       
        const signin_form = KTUtil.getById("product_basic");
        this.fv = formValidation(signin_form, {
            fields: {
                // name: {
                //     validators: {
                //         notEmpty: {
                //             message: "Name is required",
                //         },
                //     },
                // },
                // group: {
                //     validators: {
                //         notEmpty: {
                //             message: "Attribute group is required",
                //         },
                //     },
                // },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });
        this.fv.on("core.form.valid", () => {

            // set spinner to submit button
            const submitButton = this.$refs["kt_login_signin_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            setTimeout(() => {
                
                submitButton.classList.remove(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                );
            }, 500);
        });
        
    },
    methods: {
        print(){
            this.flag = !this.flag;
            this.form.address = this.form.address.split(",").join(",<br />");
        },
        newFilter() {
				this.form.products.push({
                        qty:"",
						type: "",
						price:"",
                        variants:[{
                            name:"",
                            price:""
                        }]
					});
			},
			deleteFilter(index) {
				this.form.products.splice(index, 1);
			},
            newV(index) {
				this.form.products[index].variants.push({
                            name:"",
                            price:""
                        });
			},
			deleteV(index,key) {
				this.form.products[index].variants.splice(key, 1);
			},
            printWindow: function () {		
	 window.print();
    }
    },
    
};
</script>

<style></style>
